import gsap from "gsap"

import { Element } from "@/scripts/extends";

export default class Carousel extends Element {
	mounted() {
		this.innerCarousel = this.el.querySelector(".c-carousel__inner");
		this.carouselContent = Array.from(this.el.querySelectorAll(".c-carousel__slide"));

		this.initInfiniteScroll();
	}

	initInfiniteScroll() {
		this.carouselContent.forEach(item => {
			const duplicate = item.cloneNode(true)
			duplicate.setAttribute("aria-hidden", true);
			this.innerCarousel.appendChild(duplicate);
		})

		this.animate();
	}

	animate() {
		gsap.to(this.innerCarousel, {
			translateX: "-50%",
			ease: "linear",
			duration: 50,
			repeat: -1,
		})
	}
}
