import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Element } from "@/scripts/extends";

export default class Usp extends Element {
	mounted() {
		this.cards = [...this.el.querySelectorAll(".c-usp-card")];

		this.animate();
	}

	animate() {
		gsap.registerPlugin(ScrollTrigger);

		this.mm = gsap.matchMedia();

		this.mm.add("(min-width: 760px)", () => {
			this.anim = gsap.timeline({
				scrollTrigger: {
					trigger: this.el,
					scrub: true,
					start: "50% bottom",
				}
			})

			this.anim.fromTo(this.cards[0], {
				y: 200,
				x: -50,
			}, {
				y: -200,
				x: 0,
			}, 0)

			this.anim.fromTo(this.cards[2], {
				y: 200,
				x: 50,
			}, {
				y: -200,
				x: 0,
			}, 0)

			this.anim.fromTo(this.cards[1], {
				y: 400,
			}, {
				y: -400,
			}, 0)
		})

		this.mm.add("(max-width: 760px)", () => {
			this.cards.forEach(card => {
				gsap.from(card, {
					scrollTrigger: {
						trigger: card,
						start: "50% bottom",
					},
					opacity: 0,
					duration: 1,
					ease: "power2.out",
				})
			})
		})
	}
}
