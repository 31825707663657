import gsap from "gsap";
import scrollTrigger from "gsap/ScrollTrigger";

import { Element } from "@/scripts/extends";

export default class Countdown extends Element {
	mounted() {
		this.globe = this.el.querySelector(".c-countdown-card__globe");
		this.card = this.el.querySelector(".c-countdown-card");
		this.clockNumbers = this.el.querySelectorAll("c-number");

		this.endTime = new Date(
		this.el
			.querySelector("[data-end-time]")
			.getAttribute("data-end-time"));

		this.previousValues = {
			days: null,
			hours: null,
			minutes: null
		};

		this.updateCountdown();
		this.animate();

		this.interval = setInterval(() => {
			this.updateCountdown();
		}, 1000);
	}

	updateCountdown() {
		const now = new Date();
		const timeDifference = this.endTime - now;

		if (timeDifference > 0) {
			const days = String(Math.floor(timeDifference / (1000 * 60 * 60 * 24))).padStart(2, '0');
			const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
			const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');

			if (this.previousValues.days !== days) {
				this.el.querySelector(".c-number[unit='days'] .c-number__number span").innerText = days;
				this.previousValues.days = days;
			}

			if (this.previousValues.hours !== hours) {
				this.el.querySelector(".c-number[unit='hours'] .c-number__number span").innerText = hours;
				this.previousValues.hours = hours;
			}

			if (this.previousValues.minutes !== minutes) {
				this.el.querySelector(".c-number[unit='minutes'] .c-number__number span").innerText = minutes;
				this.previousValues.minutes = minutes;
			}
		} else {
			clearInterval(this.interval);
		}
	}

	animate() {
		gsap.registerPlugin(scrollTrigger)

		this.anim = gsap.timeline({
			scrollTrigger: {
				trigger: this.el,
				start: "top 80%",
			},
		});

		this.anim.to(this.globe, {
			rotation: 360,
			duration: 75,
			ease: "linear",
			repeat: -1,
		}, 0)

		this.anim.from(this.globe, {
			y: 600,
			duration: 2,
			ease: "back",
			opacity: 0,
		}, 0)

		this.anim.from(this.card, {
			y: 400,
			duration: 1,
			opacity: 0,
			ease: "power2.out"
		}, "-=1.9")
	}
}
