import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Element } from "@/scripts/extends";

export default class Number extends Element {
	mounted() {
		this.number = this.el.querySelector(".c-number__number");
		this.label = this.el.querySelector(".c-number__label");

		this.animate();
	}

	animate() {
		gsap.registerPlugin(ScrollTrigger);

		this.anim = gsap.timeline({
			scrollTrigger: {
				trigger: this.el,
				once: true,
				onEnter: () => this.scrambleText(this.number)
			}
		})
			.from(this.number, { y: "150%", duration: 1, ease: "power2.out" })
			.from(this.label, { opacity: 0, duration: 1, ease: "power2.out" }, "-=0.5")
	}

	scrambleText(element) {
		const originalText = element.innerText;
		let intervalDelay = 50;

		const interval = setInterval(() => {
			element.innerText = element.innerText.split("").map((letter, index) => {
				let originalLetter = originalText.split("")[index];

				if (originalLetter === "+" || originalLetter === ".") {
					return originalLetter;
				} else {
					return String(Math.floor(Math.random() * 10));
				}
			}).join("");

			intervalDelay *= 2;
		}, intervalDelay);

		setTimeout(() => {
			clearInterval(interval);
			element.innerText = originalText;
		}, 1500);
	}
}
