/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import Button from "@/scripts/components/Button";
import Flyout from "@/scripts/components/Flyout";
import Slider from "@/scripts/components/Slider";
import Video from "@/scripts/components/Video";
import Tooltip from "@/scripts/components/Tooltip";
import Countdown from "@/scripts/components/Countdown";
import SmoothScroll from "@/scripts/components/SmoothScroll";
import HomeHeader from "@/scripts/components/HomeHeader";
import Carousel from "@/scripts/components/Carousel";
import Footer from "@/scripts/components/Footer";
import Fade from "@/scripts/components/Fade";
import Usp from "@/scripts/components/Usp";
import Number from "@/scripts/components/Number";
import ImageReveal from "@/scripts/components/ImageReveal";
import Trigger from "@/scripts/components/Trigger";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	Button,
	ImageReveal,
	Trigger,
	Number,
	Fade,
	Usp,
	Footer,
	Carousel,
	Flyout,
	Slider,
	Video,
	Tooltip,
	Countdown,
	SmoothScroll,
	HomeHeader,
};
