import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Element } from "@/scripts/extends";

export default class ImageReveal extends Element {
	mounted() {
		this.imageCover = this.el.querySelector(".c-picture__overlay");
		this.animate();
	}

	animate() {
		gsap.registerPlugin(ScrollTrigger);

		gsap.to(this.imageCover, {
			scrollTrigger: this.el,
			scaleX: 0,
			transformOrigin: "right center",
			duration: 1,
			ease: "power3.inOut",
		})

	}
}
