import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SplitType from 'split-type'
import { Element } from "@/scripts/extends";

export default class Fade extends Element {
	mounted() {
		this.el = new SplitType(this.el)
		this.el.lines.forEach(line => line.style.overflow = "hidden");

		this.animate();
	}

	animate() {
		gsap.registerPlugin(ScrollTrigger);

		gsap.from(this.el.words, {
			scrollTrigger: {
				trigger: this.el.lines[0],
				start: "top 90%",
			},
			y: "200%",
			duration: 1,
			ease: "power2.out",
		})
	}
}


