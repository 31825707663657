import { Element } from "@/scripts/extends";
import Native from "./Native";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Video extends Element {
	mounted() {
		this.activeClass = "is-playing";

		this.thumbnail = this.el.querySelector(".c-video__thumbnail");
		this.frame = this.el.querySelector(".c-video__frame");
		this.videoEmbedEl = this.el.querySelector(".c-video--embed");
		this.videoNativeEl = this.el.querySelector(".c-video--native");
		this.videoPlayer = this.videoEmbedEl ? this.videoEmbedEl.dataset.player.toLowerCase() : "native";

		this.playerOptions = {
			"youtube": {
				"pause": {
					"event": "command",
					"func": "pauseVideo",
				},
				"play": {
					"event": "command",
					"func": "playVideo",
				}
			},
			"vimeo": {
				"pause": {
					"method": "pause"
				},
				"play": {
					"method": "play"
				}
			}
		};
	}

	events() {
		EventEmitter.on(EVENTS.MODAL_CLOSE, () => this.handleStop());

		if (this.thumbnail) {
			this.thumbnail.addEventListener("click", () => this.handleClick());
		}

		if (this.videoEmbedEl && this.frame) {
			this.frame.addEventListener("load", () => {
				this.listenToEmbed();
				this.initEmbedReady();
			})
		}

		if (this.videoNativeEl) {
			this.videoNative = new Native(this.videoNativeEl, {
				pauseCb: () => this.handlePause(),
				endedCb: () => this.handleEnded(),
			})
		}
	}

	handleClick() {
		this.el.classList.add(this.activeClass);

		// eslint-disable-next-line no-undef
		//if (Cookiebot.consent.marketing) {
			if (this.videoNativeEl) {
				this.videoNative.play();
			}

			if (this.videoEmbedEl) {
				this.playEmbed();
			}
		//}
	}

	handlePause() {
		if (this.pauseTimeout) {
			clearTimeout(this.pauseTimeout);
		}

		this.pauseTimeout = setTimeout(() => this.handlePauseVideo(), 2000);
	}

	handlePauseVideo() {
		this.el.classList.remove(this.activeClass);
	}

	handleEnded() {
		this.el.classList.remove(this.activeClass);
	}

	handleStop() {
		if (this.videoNativeEl) {
			this.videoNative.stop();
		}
	}

	postMessageToPlayer(command) {
		if (command == null) return;

		if (this.playerOptions[this.videoPlayer]) {
			let postCommand = this.playerOptions[this.videoPlayer][command];
			this.frame.contentWindow.postMessage(JSON.stringify(postCommand), "*");
		}
	}

	initEmbedReady() {
		this.el.classList.remove("is-loading");

		if (this.el.classList.contains("js-autoplay")) {
			this.autoplayEmbed();
		}
	}

	listenToEmbed() {
		this.postMessageToPlayer({
			"event": "listening",
			"id": "1",
			"channel": "widget"
		});

		window.addEventListener('message', function (event) {
			console.log(event);
		});
	}

	autoplayEmbed() {
		this.src = this.frame.getAttribute("src");
		this.frame.setAttribute("src", `${this.src}&autoplay=1`);
	}

	playEmbed() {
		this.postMessageToPlayer("play");
	}

	pauseEmbed() {
		this.postMessageToPlayer("pause");
	}
}
