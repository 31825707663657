import Lenis from '@studio-freight/lenis'

import { Element } from "@/scripts/extends";

export default class Button extends Element {
	mounted() {
		// Initialize smooth scrolling using Lenis https://github.com/darkroomengineering/lenis
		const lenis = new Lenis()

		function raf(time) {
			lenis.raf(time)
			requestAnimationFrame(raf)
		}

		requestAnimationFrame(raf)
	}
}
