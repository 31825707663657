import { Base } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

class Mouse extends Base {
	events() {
		window.addEventListener("mousemove", this.onMouseMove);
	}

	onMouseMove(e) {
		EventEmitter.emit(EVENTS.MOUSE_MOUSE_MOVE, e);
	}

	listenOnMouseMove(callback) {
		EventEmitter.on(EVENTS.MOUSE_MOUSE_MOVE, callback);
	}
}

const mouse = new Mouse();

export default mouse;
