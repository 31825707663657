import { Element } from "@/scripts/extends";

export default class Trigger extends Element {
	mounted({ clickCb }) {
		this.clickCb = clickCb;

		this.initBinds();
	}

	initBinds() {
		this.handleClick = this.handleClick.bind(this);
	}

	events() {
		this.el.addEventListener("click", (e) => this.handleClick(e));
	}

	handleClick(e) {
		if(e) {
			this.clickCb(e);
		}
	}

	setFocus() {
		this.el.focus();
	}

	destroy() {
		this.el.removeListener("click", this.handleClick);
	}
}
