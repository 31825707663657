import Swiper from 'swiper';
import { Autoplay, Navigation, EffectFade, Thumbs } from 'swiper/modules';

import { Element } from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		if (this.el.classList.contains("swiper")) {
			Swiper.use([Autoplay, Navigation, EffectFade, Thumbs]);

			this.setSwiper();
		}
	}

	setSwiper() {
		let elConfig = this.el.dataset.swiperConfig;
		const customConfig = elConfig ? JSON.parse(elConfig) : {};

		const swiperConfig = {
			modules: [EffectFade],
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 30,
			wrapperClass: "c-slider__wrapper",
			navigation: {
				disabledClass: "is-disabled",
			},
		};

		const mergedConfig = {
			...swiperConfig,
			...customConfig
		};

		this.swiper = new Swiper(this.el, mergedConfig);
		this.swiper.init();
	}
}
