import gsap from "gsap"
import SplitType from 'split-type'
import scrollTrigger from "gsap/ScrollTrigger";

import { Element } from "@/scripts/extends";

export default class HomeHeader extends Element {
	mounted() {
		this.logo = this.el.querySelector(".c-site-logo");
		this.navLinks = this.el.querySelectorAll(".c-navigation__link");
		this.socialLinks = this.el.querySelectorAll(".c-social");

		this.leftArm = this.el.querySelector(".c-arm--left");
		this.rightArm = this.el.querySelector(".c-arm--right");
		this.text = new SplitType(this.el.querySelector(".c-hero__text"));
		this.buttons = this.el.querySelector(".c-hero__links");

		this.title = new SplitType(this.el.querySelector(".c-hero__title"));
		this.title.lines.forEach(line => line.style.overflow = "hidden");

		this.animate();
	}

	animate() {
		gsap.registerPlugin(scrollTrigger);

		this.revealAnim = gsap.timeline();

		this.revealAnim.from(this.title.words, { y: 200, stagger: 0.1, duration: 1.5, ease: "power4.out" })
			.from(this.text.lines, { y: 100, opacity: 0, ease: "power2.out", duration: 1 }, "-=1,4")
			.from(this.buttons, { y: 100, opacity: 0, ease: "power2.out", duration: 1 }, "-=1,4")
			.from(this.leftArm, { x: "-100%", y: 500, rotate: 25, duration: 2.5, ease: "back.out", onComplete: () => this.initHideAnim() }, 0)
			.from(this.rightArm, { x: "100%", y: 500, rotate: -25, duration: 2.5, delay: 0.25, ease: "back.out" }, 0)
			.from(this.logo, { opacity: 0, duration: 1, ease: "power4.out" }, "-=2.5")
			.from(this.navLinks, { opacity: 0, duration: 1, stagger: 0.1 }, "-=2.5")
			.from(this.socialLinks, { opacity: 0, duration: 1, stagger: 0.1 }, "-=2")
	}

	initHideAnim() {
		gsap.to(this.leftArm, {
			scrollTrigger: {
				trigger: this.el,
				start: "top top",
				end: "bottom top",
				scrub: 3,
			},
			x: "-100%",
			transformOrigin: "top left",
			y: 200,
			rotate: 25,
			opacity: 0,
			ease: "back.inOut",
		})

		gsap.to(this.rightArm, {
			scrollTrigger: {
				trigger: this.el,
				start: "top top",
				end: "bottom top",
				scrub: 3,
			},
			x: "100%",
			transformOrigin: "top left",
			y: 200,
			rotate: -25,
			opacity: 0,
			ease: "back.inOut",
		})
	}
}
