import gsap from "gsap";
import mouse from "../singletons/Mouse";
import { Element } from "@/scripts/extends";

export default class Footer extends Element {
	mounted() {
		this.hand = this.el.querySelector(".c-footer__hand");
		this.footer = this.el;

		this.followMouseCursor = this.followMouseCursor.bind(this);

		mouse.listenOnMouseMove(this.followMouseCursor);
	}

	followMouseCursor(event) {
		this.mm = gsap.matchMedia();

		this.mm.add("(min-width: 960px)", () => {
			const mouseX = event.clientX;
			const mouseY = event.clientY;

			const boundX = window.innerWidth * 0.08;
			const boundY = 40;

			const handX = (mouseX - window.innerWidth / 2) * 0.4
			const handY = (mouseY - window.innerHeight / 2) * 0.4
			const handRotation = (handX / boundX) * 8;

			if (this.footer.contains(event.target)) {
				gsap.to(this.hand, {
					x: Math.min(Math.max(handX, -boundX), boundX),
					y: Math.min(Math.max(handY, 0), boundY) + 50,

					rotate: handRotation,
					duration: 0.6,
				})
			} else {
				gsap.to(this.hand, {
					x: 0,
					y: 0,
					rotate: 0,
					duration: 1,
				})
			};
		})
	}
}
